import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import GrantList from '../../../GrantList';
import SsoWsClient from '../../../../wsClient/SsoWsClient';

const ssoWs = new SsoWsClient();

function getProducts(onSuccess, onError) {
    const events = { onSuccess, onError };
    return ssoWs.getAllProducts(events);
}

function mapProducts(response) {
    const products = {};

    response.products.forEach((product) => {
        products[product.productId] = {
            name: product.name,
            type: product.type,
            provider: product.providerName,
        };
    });

    return products;
}

function getProductLabel(product) {
    return `${product.name} - ${product.type}`;
}

/**
 * This component is displayed in a user's edit popin and is used to edit, add or remove grants.
 *
 * It's an EntityManager inside another EntityManager (the list of the users)
 */
function UserGrantsList({ email, uuid }) {
    const onGrantAdding = useCallback(
        (grantToAdd, onSuccess, onError) => {
            grantToAdd.userId = email;

            const events = { onSuccess, onError };
            ssoWs.addGrantForUser(grantToAdd, email, events);
        },
        [email]
    );

    const onGrantEditing = useCallback(
        (grantToUpdate, onSuccess, onError) => {
            const events = { onSuccess, onError };
            ssoWs.updateGrant(grantToUpdate, email, grantToUpdate.productId, events);
        },
        [email]
    );

    const onGrantDeleting = useCallback(
        (grantToDelete, onSuccess, onError) => {
            const events = { onSuccess, onError };
            ssoWs.deleteProductGrant(email, grantToDelete.productId, null, events);
        },
        [email]
    );

    const getTempGrants = useCallback(
        (grants, onSuccess, onError) => {
            const events = {
                onSuccess: ({ response }) => {
                    onSuccess(
                        response.accessList.concat(
                            grants.filter((grant) => {
                                let found = false;

                                response.accessList.forEach((access) => {
                                    if (access.productId === grant.productId) {
                                        found = true;
                                    }
                                });

                                return !found;
                            })
                        )
                    );
                },
                onError,
            };
            return ssoWs.getUserTempGrants(uuid, events);
        },
        [uuid]
    );

    const getGrants = useCallback(
        (onSuccess, onError) => {
            const events = {
                onSuccess: ({ response }) => {
                    getTempGrants(response.accessList, onSuccess, onError);
                },
                onError,
            };
            return ssoWs.getUserGrants(email, events);
        },
        [email, getTempGrants]
    );

    return (
        <GrantList
            key={email}
            mapEntities={mapProducts}
            grantLabelFunction={getProductLabel}
            entityKeyName="productId"
            entityLabel="Product Name"
            getGrants={getGrants}
            getEntities={getProducts}
            handleGrantToAdd={onGrantAdding}
            handleGrantToEdit={onGrantEditing}
            handleGrantToDelete={onGrantDeleting}
        />
    );
}

UserGrantsList.propTypes = {
    /** User identifier in order to execute add, edit, or delete requests */
    email: PropTypes.string.isRequired,
    uuid: PropTypes.string.isRequired,
};

export default UserGrantsList;
