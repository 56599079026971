import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { format, add } from 'date-fns';
import {
    Dialog,
    DialogTitle,
    DialogActions,
    Button,
    InputLabel,
    Select,
    MenuItem,
    FormControl,
    IconButton,
    Grid,
    Switch,
    Typography,
    TextField,
    Chip,
    ListSubheader,
    styled,
    Autocomplete,
    Checkbox,
    createFilterOptions,
} from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

import { usePreferences } from '@braincube/header';
import { useI18n } from '@braincube/i18n';

import ACCESS_TYPES from 'components/GrantList/accessTypes';
import { getAvailableGrantsLevels } from 'components/Routes/Sso/Products/ProductGrantsList';
import ListboxComponent from 'components/ListboxComponent';

import FormField from '../FormField';

export const StyledCheckbox = styled(Checkbox)(({ theme }) => ({
    marginRight: theme.spacing(1),
}));

const StyledTitle = styled(`div`)({
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
});

const StyledCloseButton = styled(IconButton)(({ theme }) => ({
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
}));

const StyledSwitchWrapper = styled(`div`)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(0.5, 0),
}));

const renderGroup = (params) => {
    if (!params.group) {
        return params.children;
    }

    return [
        <ListSubheader key={params.key} component="div">
            {params.group}
        </ListSubheader>,
        params.children,
    ];
};

const renderDateTimePickerInput = (props) => <TextField fullWidth {...props} />;

const checkboxIcon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedCheckboxIcon = <CheckBoxIcon fontSize="small" />;

function renderOption(props, option, { selected }) {
    return (
        <div {...props}>
            <StyledCheckbox icon={checkboxIcon} checkedIcon={checkedCheckboxIcon} checked={selected} />
            {option.title}
        </div>
    );
}

const inputLabelProps = {
    shrink: true,
};

function getOptionLabel(option) {
    return option.title;
}

function isOptionEqualToValue(option, value) {
    return option.value === value.value;
}

function okLabel() {
    return (
        <Button variant="contained" color="primary">
            Ok
        </Button>
    );
}

const filterOptions = createFilterOptions({
    stringify: (option) => `${option.title} ${option.value}`,
});

/**
 * A Popin to add a grant
 */
function GrantAddition(props) {
    const [accessType, setAccessType] = useState('');
    const [selectedEntities, setSelectedEntities] = useState([]);
    const [selectedDate, setSelectedDate] = useState(null);
    const [search, setSearch] = useState('');
    const { dateFormat, timeFormat, locale } = usePreferences();
    const i18n = useI18n();

    const availableGrantLevels = useMemo(() => {
        try {
            // USERS
            if (props.productType) {
                return getAvailableGrantsLevels(props.productType);
            }

            // PRODUCTS
            const selectedProductsTypes = [
                ...new Set(
                    Object.keys(props.availableEntitiesForAdding)
                        .filter((uuid) => selectedEntities.find((entity) => entity.value === uuid))
                        .map((uuid) => props.availableEntitiesForAdding[uuid].type)
                ),
            ];

            return [...new Set(selectedProductsTypes.map((type) => getAvailableGrantsLevels(type)).flat(1))];
        } catch (e) {
            return Object.values(ACCESS_TYPES);
        }
    }, [props, selectedEntities]);

    useEffect(() => {
        if (availableGrantLevels.length) {
            setAccessType(availableGrantLevels[0]);
        } else {
            setAccessType('');
        }
    }, [availableGrantLevels]);

    const handleDateChange = useCallback((date) => {
        setSelectedDate(date);
    }, []);

    const handleValidate = useCallback(() => {
        selectedEntities.forEach((entity) => {
            props.onValidate({
                accessType,
                [props.entityKeyName]: entity.value,
                expireDate: selectedDate ? selectedDate.getTime() : null,
            });
        });

        props.onClose();
    }, [accessType, props, selectedDate, selectedEntities]);

    function getSortedProducts() {
        return Object.keys(props.availableEntitiesForAdding).sort((entityKey, nextEntityKey) => {
            return props
                .labelFunction(props.availableEntitiesForAdding[entityKey])
                .localeCompare(props.labelFunction(props.availableEntitiesForAdding[nextEntityKey]));
        });
    }

    const createEntitiesList = getSortedProducts().map((key) => {
        return {
            title: props.labelFunction(props.availableEntitiesForAdding[key]),
            value: key,
        };
    });

    const groupProductsList = createEntitiesList
        .map((option) => {
            return {
                provider: props.availableEntitiesForAdding[option.value].provider,
                ...option,
            };
        })
        .sort((a, b) => a?.provider?.localeCompare(b?.provider));

    const renderTags = useCallback(
        (value, getTagProps) =>
            value.map((option, index) => {
                return (
                    <Chip
                        {...getTagProps({ index })}
                        label={
                            props.entityKeyName === 'productId' ? `${option.title} - ${option.provider}` : option.title
                        }
                    />
                );
            }),
        [props.entityKeyName]
    );

    const groupBy = useCallback(
        (options) => {
            return props.entityKeyName === 'productId' ? options.provider : null;
        },
        [props.entityKeyName]
    );

    const renderInput = useCallback(
        (params) => (
            <TextField
                {...params}
                label={props.entityLabel}
                placeholder={i18n.tc('ssoAdmin.search')}
                InputLabelProps={inputLabelProps}
            />
        ),
        [i18n, props.entityLabel]
    );

    const onInputChange = useCallback((e, value, reason) => {
        if (reason === 'reset') {
            return;
        }

        setSearch(value);
    }, []);

    const handleTagsChange = useCallback((event, newEntities) => setSelectedEntities(newEntities), []);

    const handleAccessTypeChange = useCallback((e) => setAccessType(e.target.value), []);

    const handleTemporaryGrantChange = useCallback((e) => {
        return e.target.checked ? setSelectedDate(add(new Date(), { weeks: 1 })) : setSelectedDate(null);
    }, []);

    const cancelLabel = useMemo(() => <Button color="secondary">{i18n.tc('ssoAdmin.actions.cancel')}</Button>, [i18n]);

    const labelFunc = useCallback((date) => format(date, `${dateFormat} ${timeFormat}`), [dateFormat, timeFormat]);

    return (
        <Dialog open fullWidth onClose={props.onClose}>
            <DialogTitle>
                <StyledTitle>
                    <AddIcon />
                    Add grant
                    <StyledCloseButton aria-label="Close" onClick={props.onClose} size="large">
                        <CloseIcon />
                    </StyledCloseButton>
                </StyledTitle>
            </DialogTitle>
            <FormField>
                <Grid container direction="column" spacing={2}>
                    <Grid item>
                        <Autocomplete
                            filterOptions={filterOptions}
                            groupBy={groupBy}
                            renderInput={renderInput}
                            onInputChange={onInputChange}
                            inputValue={search || ''}
                            renderOption={renderOption}
                            options={props.entityKeyName === 'productId' ? groupProductsList : createEntitiesList}
                            getOptionLabel={getOptionLabel}
                            isOptionEqualToValue={isOptionEqualToValue}
                            value={selectedEntities}
                            onChange={handleTagsChange}
                            multiple
                            disableCloseOnSelect
                            renderTags={renderTags}
                            ListboxComponent={ListboxComponent}
                            renderGroup={renderGroup}
                        />
                    </Grid>
                    <Grid item>
                        <FormControl fullWidth>
                            <InputLabel>Access type</InputLabel>
                            <Select label="Access type" value={accessType} onChange={handleAccessTypeChange}>
                                {availableGrantLevels.map((access) => (
                                    <MenuItem key={access} value={access}>
                                        {access}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item>
                        <StyledSwitchWrapper>
                            <Typography>Temporary grant</Typography>
                            <Switch checked={selectedDate !== null} onChange={handleTemporaryGrantChange} />
                        </StyledSwitchWrapper>
                    </Grid>
                    <Grid item>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DateTimePicker
                                ampm={locale !== 'fr'}
                                fullWidth
                                disabled={selectedDate === null}
                                label={i18n.tc('ssoAdmin.grant.fields.expirationDate')}
                                value={selectedDate || add(new Date(), { weeks: 1 })}
                                onChange={handleDateChange}
                                cancelLabel={cancelLabel}
                                okLabel={okLabel}
                                disablePast
                                labelFunc={labelFunc}
                                renderInput={renderDateTimePickerInput}
                            />
                        </LocalizationProvider>
                    </Grid>
                </Grid>
            </FormField>
            <DialogActions>
                <Button onClick={props.onClose} color="secondary">
                    {i18n.tc('ssoAdmin.actions.cancel')}
                </Button>
                <Button variant="contained" color="primary" onClick={handleValidate}>
                    {i18n.tc('ssoAdmin.actions.add')}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

GrantAddition.propTypes = {
    /** Callback when the popin closes */
    onClose: PropTypes.func.isRequired,
    /** Callback when the validate button is clicked. The new grant is passed as parameter. */
    onValidate: PropTypes.func.isRequired,
    /** The full list of available entities (products or users) */
    availableEntitiesForAdding: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    /** The label of the entity (such as Email or Product Name) */
    entityLabel: PropTypes.string.isRequired,
    entityKeyName: PropTypes.string.isRequired,
    /** optional formatting function for label, otherwise we use tostring */
    labelFunction: PropTypes.func,
    productType: PropTypes.string,
};

GrantAddition.defaultProps = {
    labelFunction: (item) => item.toString(),
    productType: null,
};

export default GrantAddition;
